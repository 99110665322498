import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Collapse} from "reactstrap";
import {Link, useHistory, withRouter} from "react-router-dom";
import classname from "classnames";

//i18n
import {withTranslation} from "react-i18next";

import {connect} from "react-redux";

import {getArticleGroupList, getCateoryList, getFavourites} from "../../store/e-commerce/actions";
import UserImpersonation from "../../pages/Authentication/UserImpersonation";
import {showRightSidebarAction, toggleLeftmenu,} from "../../store/actions";
import {equalsAny, hasModuleEnabled} from "../../util/helperFunctions";

const Navbar = (props) => {
    const {categoryList, onGetCategoryList, onGetGroupList, groupList, favourites, onGetFavourites} = props;
    const [ui, setui] = useState(false);
    const [admin, setadmin] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [brandSettings, setBrandSettings] = useState(null);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetCategoryList();
        onGetGroupList();
        onGetFavourites();
    }, []);

    useEffect(() => {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (items[i].pathname && props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    });

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    }

    const handleGlobalSearch = () => {
        history.push("/search?value=" + searchValue);
    };

    function ProductsMenu() {
        return (
            <ul className={classname("dropdown-menu multi-level", {show: ui})} role="menu" aria-labelledby="dropdownMenu"
                style={{backgroundColor: brandSettings ? brandSettings?.primaryColor : "#5f6972"}}>
                {categoryList.map(function (category, index) {
                    return (
                        <li class={category.subCategories.length > 0 ? "dropdown-submenu" : "dropdown-item"}>
                            <Link
                                to={`/customer/categories/${category.id}/subcategory`}
                                class={category.subCategories.length > 0 ? "dropdown-item text-cd" : "text-cd"}
                            >
                                {category.name}
                            </Link>
                            {category.subCategories.length > 0 &&
                                <ul class="dropdown-menu" style={{backgroundColor: brandSettings ? brandSettings?.primaryColor : "#5f6972", maxHeight: "500px", overflow: "auto"}}>
                                    {category.subCategories.map(function (
                                        subCategory,
                                        index
                                    ) {
                                        return (
                                            <li class="dropdown-item">
                                                <Link
                                                    key={"nav_subcategory_" + index}
                                                    className="text-cd"
                                                    to={
                                                        subCategory.subCategories.length > 0
                                                            ? `/customer/categories/${subCategory.id}/subcategory`
                                                            : `/customer/categories/${subCategory.id}/articles`
                                                    }
                                                >
                                                    {subCategory.name}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>}
                        </li>
                    )
                })}
                <li className="dropdown-divider"></li>
                {groupList?.length > 0 && <>
                    <li className="dropdown-submenu">
                        <label className={"dropdown-item text-cd"}>
                            {props.t("Article Groups")}
                        </label>
                        <ul className="dropdown-menu" style={{backgroundColor: brandSettings ? brandSettings?.primaryColor : "#5f6972", maxHeight: "500px", overflow: "auto"}}>
                            {groupList?.map(function (group) {
                                if (group?.noOfArticles > 0) {
                                    return (
                                        <li className="dropdown-item">
                                            <Link
                                                key={"nav_subcategory_"}
                                                className="text-cd"
                                                to={`/customer/group/${group.id}/articles`}
                                            >
                                                {group.name}
                                            </Link>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </li>
                    <li className="dropdown-divider"></li>
                </>}

                <li class={favourites.length > 0 ? "dropdown-submenu" : "dropdown-item"}>
                    <Link
                        to={`/customer/productLists`}
                        class={favourites.length > 0 ? "dropdown-item text-cd" : "text-cd"}>
                        {props.t("Product Lists")}
                    </Link>
                    {favourites.length > 0 &&
                        <ul class="dropdown-menu" style={{backgroundColor: brandSettings ? brandSettings?.primaryColor : "#5f6972", maxHeight: "500px", overflow: "auto"}}>
                            {favourites.map(function (
                                favourite,
                                index
                            ) {
                                return (
                                    <li class="dropdown-item">
                                        <Link
                                            key={"favorite_" + index}
                                            className="text-cd"
                                            to={`/customer/productLists/${favourite.id}/articles`}
                                        >
                                            {props.t(favourite.name)}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>}
                </li>
                <li class="dropdown-item">
                    <Link to={"/customer/articles"} className="text-cd">
                        {props.t("View All")}
                    </Link>
                </li>
            </ul>
        )
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="topnav" style={{backgroundColor: brandSettings ? brandSettings?.primaryColor : "#5f6972"}}>
                    <nav
                        className="navbar navbar-dark  navbar-expand-lg topnav-menu"
                        id="navigation"
                    >
                        <Collapse
                            isOpen={props.leftMenu}
                            className="navbar-collapse"
                            id="topnav-menu-content"
                        >
                            <ul className="navbar-nav ">
                                <li className="nav-item ">
                                    <Link className="nav-link text-cd  " to="/customer/categories">
                                        <i className="uil-home-alt me-2"></i> {props.t("Home")}
                                    </Link>
                                </li>

                                {hasModuleEnabled (user?.tenantFeatures, "ARTICLES") &&
                                <li className="nav-item dropdown">
                                    <Link
                                        to={"/customer/categories"}
                                        onClick={() => {
                                            setui(!ui);
                                        }}
                                        className="nav-link text-cd dropdown-toggle arrow-none"
                                    >
                                        <i className="uil-store me-2"></i>
                                        {props.t("Products")}
                                        <div className="arrow-down"></div>
                                    </Link>
                                    <ProductsMenu/>
                                </li>
                                }

                                {hasModuleEnabled (user?.tenantFeatures, "OFFERS") &&
                                <li className="nav-item">
                                    <Link className="nav-link text-cd" to="/customer/offers">
                                        <i className="uil-bolt-alt me-2"></i> {props.t("Offers")}
                                    </Link>
                                </li>
                                }

                                {hasModuleEnabled (user?.tenantFeatures, "ORDERS") &&
                                <li className="nav-item">
                                    <Link className="nav-link text-cd" to="/customer/orders">
                                        <i className="uil-history me-2"></i> {props.t("Orders")}
                                    </Link>
                                </li>
                                }

                                {hasModuleEnabled (user?.tenantFeatures, "PROJECTS") &&
                                <li className="nav-item">
                                    <Link className="nav-link text-cd" to="/projects">
                                        <i className="uil-apps me-2"></i> {props.t("Projects")}
                                    </Link>
                                </li>
                                }

                                {hasModuleEnabled (user?.tenantFeatures, "TASKS") && <>
                                <li className="nav-item">
                                    <Link className="nav-link text-cd" to="/customer/tasks">
                                        <i className="uil-channel-add me-2"></i> {props.t("Tasks")}
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link text-cd" to="/customer/timeslips">
                                        <i className="uil-clock me-2"></i> {props.t("Timeslips")}
                                    </Link>
                                </li>
                                </>}
                                {user?.roles?.includes("ROLE_CUSTOMER_ADMIN") && (
                                    <li className="nav-item dropdown">
                                        <Link
                                            to="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setadmin(!admin);
                                            }}
                                            className="nav-link text-cd dropdown-togglez arrow-none"
                                        >
                                            <i className="uil-user-md me-2 text-cd"></i>
                                            {props.t("Admin")}
                                            <div className="arrow-down"></div>
                                        </Link>
                                        <div className={classname("dropdown-menu", {show: admin})}
                                             style={{backgroundColor: brandSettings ? brandSettings?.primaryColor : "#5f6972"}}>
                                            <Link
                                                to="/customer/deliveryAddresses"
                                                className="dropdown-item text-cd"
                                            >
                                                {props.t("Delivery Addresses")}
                                            </Link>

                                            {hasModuleEnabled (user?.tenantFeatures, "EVENTS") &&
                                            <Link
                                                to="/customer/events"
                                                className="dropdown-item text-cd"
                                            >
                                                {props.t("Events")}
                                            </Link>
                                            }
                                            {hasModuleEnabled (user?.tenantFeatures, "INVOICE") &&
                                            <Link
                                                to="/customer/invoices"
                                                className="dropdown-item text-cd"
                                            >
                                                {props.t("Invoices")}
                                            </Link>
                                            }
                                            <Link
                                                to="/customer/users"
                                                className="dropdown-item text-cd"
                                            >
                                                {props.t("Users")}
                                            </Link>
                                        </div>
                                    </li>)}
                            </ul>
                            <div className="cs-hide-xl">
                                {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_ACTING_USER"]) && (
                                    <UserImpersonation/>
                                )}
                            </div>
                        </Collapse>
                        <form
                            className="app-search"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleGlobalSearch();
                            }}
                        >
                            <div className="position-relative cs-hide-sm">
                                <input
                                    type="text"
                                    className="form-control white-color"
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    placeholder={props.t("Search") + "..."}
                                />
                                <span className="uil-search white-color"></span>
                            </div>
                        </form>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    );
};

Navbar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    toggleLeftmenu: PropTypes.func,
    t: PropTypes.any,
    categoryList: PropTypes.array,
    onGetCategoryList: PropTypes.func,
    showRightSidebarAction: PropTypes.func,
    groupList: PropTypes.array,
    onGetGroupList: PropTypes.func
};

const mapStatetoProps = (state) => {
    const {layoutType, showRightSidebar, leftMenu} = state.Layout;
    return {
        layoutType,
        showRightSidebar,
        leftMenu,
        categoryList: state.ecommerce.categoryList,
        groupList: state.ecommerce.groupList,
        favourites: state.ecommerce.favourites,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetCategoryList: () => dispatch(getCateoryList()),
        onGetGroupList: () => dispatch(getArticleGroupList()),
        toggleLeftmenu,
        showRightSidebarAction,
        onGetFavourites: () => dispatch(getFavourites()),
    };
};

export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(withTranslation()(Navbar))
);
